import React from "react";
import { useEffect, useState } from "react";
import {
  editionsBagourdContract,
  connectWallet,
  buyBooks,
  loadCurrentMessage,
  loadCurrentPrice,
  loadCurrentStock,
  laodDeliveryFee,
  getAmountToPay,
  getCurrentWalletConnected,
} from "./util/interact.js";

import eblogo from "./eb.svg";
import ufs from "./ufs2(1).jpg";
import BuyPopup from "./components/BuyPopup.js";

const EditionsBagourd = () => {
  //state variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("No connection to the network."); //default message
  const [price, setPrice] = useState("No connection to the network.");
  const [stock, setStock] = useState("No connection to the network.");
  const [deliveryFee, setDeliveryFee] = useState(
    "No connection to the network."
  );
  const [newQuantity, setQuantityToBuy] = useState("");
  const [newName, setName] = useState("");
  const [newEmail, setEmail] = useState("");
  const [totalToPay, setTotalToPay] = useState("");
  const [popup, setPopup] = useState(false);

  //called only once
  useEffect(async () => {
    const totalToPay = 0;
    const newQuantity = 0;
    setTotalToPay(totalToPay);
    const price = await loadCurrentPrice(1);
    const stock = await loadCurrentStock(1);
    const message = await loadCurrentMessage();
    const deliveryFee = await laodDeliveryFee();
    setMessage(message);
    setPrice(price);
    setStock(stock);
    setDeliveryFee(deliveryFee);
    //addSmartContractListener();

    const { address, status } = await getCurrentWalletConnected();

    setWallet(address);
    setStatus(status);

    addWalletListener();
  }, []);

  function addSmartContractListener() {
    editionsBagourdContract.events.order({}, (error, data) => {
      if (error) {
        setStatus("😥 " + error.message);
      } else {
        setMessage(data.returnValues[1]);
        setQuantityToBuy("");
        setStatus("🎉 Your message has been updated!");
      }
    });
  }

  const handleQuantityChange = (e) => {
    setQuantityToBuy(e.target.value);
    updateTotalToPay(e.target.value);
  };

  const updateTotalToPay = async (b) => {
    const a = await getAmountToPay([1], [b]);
    setTotalToPay(a);
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          //setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onUpdatePressed = async () => {
    const { status } = await buyBooks(
      walletAddress,
      [1],
      [newQuantity],
      newName,
      newEmail
    )
      .then(() => setPopup(false))
      .catch(() => setPopup(false));
    setStatus(status);
  };

  //the UI of our component
  return (
    <div id="container">
      {popup && (
        <BuyPopup
          newName={newName}
          setName={setName}
          newEmail={newEmail}
          setEmail={setEmail}
          status={status}
          handleQuantityChange={handleQuantityChange}
          newQuantity={newQuantity}
          totalToPay={totalToPay}
          onUpdatePressed={onUpdatePressed}
          setPopup={setPopup}
        />
      )}
      <nav className="navbar">
        <a href='https://www.editions.bagourd.com'>
            <img id="logo" src={eblogo} />
        </a>
        <button
          id="walletButton"
          className="btn-desktop"
          onClick={connectWalletPressed}
        >
          {walletAddress.length > 0 ? (
            "Connected: " +
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
          ) : (
            <span>Connect Wallet</span>
          )}
        </button>
        <button
          id="walletButton"
          className="btn-mobile"
          onClick={connectWalletPressed}
        >
          {walletAddress.length > 0 ? (
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
          ) : (
            <span>Connect Wallet</span>
          )}
        </button>
      </nav>
      <div className="content-wrapper">
        <div
          style={{
            width: "100%",
          }}
        >
          <h2
            style={{
              fontWeight: "800",
            }}
          >
            Comment passe-t-on d'esclave à invité de la Maison Blanche ?
          </h2>
          <br />
          <br />
          <p>
            En 1848, Booker a huit ans lorsque l’esclavage est officiellement
            aboli. Désormais libre, son futur lui appartient. Travaillant le
            jour au service des Blancs, et s’instruisant la nuit, Booker est
            l’incarnation de l'ambition. Il parvient à s’inscrire à l’école où
            il va parfaire son éducation et deviendra ainsi fondateur d’une
            école destinée aux Noirs dont il sera le directeur. La question
            raciale est au cœur de son engagement et toute sa vie il ne cessera
            d’y sensibiliser la population américaine.
            <br></br>
            <br></br>Entre apprentissage et recherche de liberté, cette
            autobiographie témoigne de la dure réalité du racisme au XIXème
            siècle.
            <br></br>
            <br></br>Titre original: Up From Slavery.
          </p>
          <h3 style={{ paddingTop: "50px" }}>Price: ${price}.00</h3>
          <h3>Quantity in stock: {stock}</h3>
          <h3>Delivery fee: ${deliveryFee}.00</h3>
          <button
            id="publish"
            disabled={walletAddress.length == 0}
            onClick={() => setPopup(true)}
            style={{
              backgroundColor: walletAddress.length == 0 && "gray",
              marginTop:50
            }}
          >
            Buy
          </button>
        </div>
        {/* <img align="right" id="book-image" src={ufs} /> */}
        <div style={{ width: "auto" }}>
          <div className="flip-container">
            <div className="flipper">
              <img src="/front.jpg" className="front" />
              {/* <img src="/back.jpg" className="back" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditionsBagourd;
