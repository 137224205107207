import React from "react";

const BuyPopup = ({
  newName,
  setName,
  newEmail,
  setEmail,
  status,
  handleQuantityChange,
  newQuantity,
  totalToPay,
  onUpdatePressed,
  setPopup,
}) => {
  return (
    <div className="popup">
      <div className="popup-bg" onClick={() => setPopup(false)} />
      <div className="popup-container">
        <input
          type="text"
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          value={newName}
        />
        <br />
        <input
          type="text"
          placeholder="Email address"
          onChange={(e) => setEmail(e.target.value)}
          value={newEmail}
        />
        <br />
        <input
          type="number"
          placeholder="quantity"
          onChange={handleQuantityChange}
          value={newQuantity}
        />
        <p id="status">{status}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p style={{ paddingTop: "18px" }}>
            Total to pay for {newQuantity} books: ${totalToPay}.00
          </p>
          <br />
          <button
            id="publish"
            onClick={onUpdatePressed}
          >
            Buy
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuyPopup;
