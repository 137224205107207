require('dotenv').config();
const alchemyKey = "pLGG-4shGAj3_6Sg_E6qBOUM2Dx3KasW";
// @TODO: Make it such that we can read it from a file
//require("./alchemy.json")[0]["key"];
const alchemyUrl = "wss://eth-goerli.g.alchemy.com/v2/" + alchemyKey;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyUrl);

const contractABI = require("../contract-abi.json");
const erc20ContractABI = require("../erc20-abi.json");
const EB = "0xaC156478FA93293cc7B0DBD5FbE7e2e9Abe1f121";
const contractAddress = "0x224A2B9a79E8bCCcBcfDb2f4d0195b5dAC9D0046";
const USDC = "0x07865c6E87B9F70255377e024ace6630C1Eaa37F";

export const editionsBagourdContract = new web3.eth.Contract(
  contractABI,
  contractAddress
);



export const erc20USDC = new web3.eth.Contract(erc20ContractABI, USDC);

export const loadCurrentMessage = async () => {
  const message = await editionsBagourdContract.methods
    .getAmountToPay([1], [2])
    .call();
  return message;
};

export const loadCurrentPrice = async (x) => {
  const price = await editionsBagourdContract.methods
    .bookIdsToPrices([x])
    .call();
  return price;
};

export const loadCurrentStock = async (x) => {
  const stock = await editionsBagourdContract.methods
    .bookIdsToStocks([x])
    .call();
  return stock;
};

export const laodDeliveryFee = async () => {
  const fee = await editionsBagourdContract.methods.getDeliveryFee().call();
  return fee;
};

export const getAmountToPay = async (bookIds, quantities) => {
  const amountToPay = await editionsBagourdContract.methods
    .getAmountToPay(bookIds, quantities)
    .call();
  return amountToPay;
};

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const approveUSDC = async (address, amountToPay) => {
  if (!window.ethereum || address === null) {
    return {
      status:
        "💡 Connect your Metamask wallet to update the message on the blockchain.",
    };
  }

  const transactionParameters = {
    to: USDC, // Required except during contract publications.
    from: address, // must match user's active address.
    data: erc20USDC.methods.approve(contractAddress, amountToPay).encodeABI(),
  };

  //sign the transaction
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      status: (
        <span>
          ✅{" "}
          <a target="_blank" href={`https://goerli.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
          <br />
          ℹ️ Once the transaction is verified by the network, the message will
          be updated automatically.
        </span>
      ),
    };
  } catch (error) {
    return {
      status: "😥 " + error.message,
    };
  }
};

export const buyBooks = async (
  address,
  bookIds,
  quantities,
  customerName,
  customerEmail
) => {
  if (!window.ethereum || address === null) {
    return {
      status:
        "💡 Connect your Metamask wallet to update the message on the blockchain.",
    };
  }

  if (customerName.trim() === "") {
    return {
      status: "❌ Your customerName cannot be an empty string.",
    };
  }

  if (customerEmail.trim() === "") {
    return {
      status: "❌ Your customerEmail cannot be an empty string.",
    };
  }

  const amountToPay = await getAmountToPay(bookIds, quantities);
  const { status } = await approveUSDC(address, amountToPay);

  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: address, // must match user's active address.
    data: editionsBagourdContract.methods
      .buyBooks(
        customerName,
        bookIds,
        quantities,
        "0x07865c6E87B9F70255377e024ace6630C1Eaa37F"
      )
      .encodeABI(),
  };

  //sign the transaction
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      status: (
        <span>
          ✅{" "}
          <a target="_blank" href={`https://goerli.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
          <br />
          ℹ️ Once the transaction is verified by the network, the message will
          be updated automatically.
        </span>
      ),
    };
  } catch (error) {
    return {
      status: "😥 " + error.message,
    };
  }
};

